import { store } from './store';
import { setTitle, addLoading, removeLoading } from './store/features/viewSlice';

const exportedObject = {
  setTitle (title) {
    store.dispatch(setTitle(title));
  },
  showLoading (name) {
    store.dispatch(addLoading(name));
  },
  hideLoading (name) {
    store.dispatch(removeLoading(name));
  }
};

export default exportedObject;
