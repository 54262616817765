import React, {lazy} from 'react';
const Home = lazy(() => import('./../views/Home'));
const Users = lazy(() => import('./../views/Users'));
const User = lazy(() => import('./../views/User'));
const Orders = lazy(() => import('./../views/Orders'));
const OrdersSample = lazy(() => import('./../views/OrdersSample'));
const OrdersRecurrent = lazy(() => import('./../views/OrdersRecurrent'));
const OrdersSummary = lazy(() => import('./../views/OrdersSummary'));
const Order = lazy(() => import('./../views/Order'));
const Setup = lazy(() => import('./../views/Setup'));
const Test = lazy(() => import('./../views/Test'));
const LostPassword = lazy(() => import('./../views/LostPassword'));
const Invitation = lazy(() => import('./../views/Invitation'));
const InvitationAccept = lazy(() => import('./../views/InvitationAccept'));
const ProductionSummary = lazy(() => import('./../views/ProductionSummary'));
const UserOrderItemsSummary = lazy(() => import('./../views/UserOrderItemsSummary'));

const routes = [
  // Home
  {
    key: 'home',
    path: "/",
    exact: true,
    component: <Home />,
    loggedIn: true,
  },
  // Users
  {
    key: 'users',
    path: "/users",
    component: <Users />,
    loggedIn: true,
  },
  {
    key: 'user',
    path: "/user/:id",
    component: <User />,
    loggedIn: true,
  },
  {
    key: 'new-user',
    path: "/user",
    component: <User />,
    loggedIn: true,
  },
  {
    key: 'invitation',
    path: "/invitation",
    component: <Invitation />,
    loggedIn: true,
  },
  {
    key: 'invitation-accept',
    path: "/invitation-accept",
    component: <InvitationAccept />,
  },
  // Orders
  {
    key: 'orders',
    path: "/orders",
    component: <Orders />,
    loggedIn: true,
  },
  {
    key: 'order',
    path: "/order/:id",
    component: <Order />,
    loggedIn: true,
  },
  {
    key: 'new-order',
    path: "/order",
    component: <Order />,
    loggedIn: true,
  },
  {
    key: 'orders-sample',
    path: "/orders-sample",
    component: <OrdersSample />,
    loggedIn: true,
  },
  {
    key: 'orders-recurrent',
    path: "/orders-recurrent",
    component: <OrdersRecurrent />,
    loggedIn: true,
  },
  {
    key: 'orders-summary',
    path: "/orders-summary",
    component: <OrdersSummary />,
    loggedIn: true,
  },
  // Settings
  {
    key: 'setup',
    path: "/setup",
    component: <Setup />,
    loggedIn: true,
  },
  // Production Summary
  {
    key: 'production-summary',
    path: "/production-summary",
    component: <ProductionSummary />,
    loggedIn: true,
  },
  // Production Summary
  {
    key: 'user-order-items-summary',
    path: "/user-order-items-summary",
    component: <UserOrderItemsSummary />,
    loggedIn: true,
  },
  // Lost password
  {
    key: 'lost-password',
    path: "/lost-password",
    component: <LostPassword />,
  },
  // Others
  {
    key: 'test',
    path: "/test",
    component: <Test />,
  }
];
export default routes;
