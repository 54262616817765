import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: null, // user authentication token
	refresh_token: null, // user authentication refresh token
  user: null, // authenticated user
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setRefreshToken: (state, action) => {
      state.refresh_token = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    clearToken: (state) => {
      state.token = null;
    },
    clearRefreshToken: (state) => {
      state.refresh_token = null;
    },
    clearUser: (state) => {
      state.user = null;
    }
  },
});

// export actions
export const { setToken, setRefreshToken, setUser, clearToken, clearRefreshToken, clearUser } = authSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.auth.value)`
export const selectToken = (state) => state.auth.token;
export const selectRefreshToken = (state) => state.auth.refresh_token;
export const selectUser = (state) => state.auth.user;

// export reducers
export default authSlice.reducer;
