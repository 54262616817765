import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  online: true,
  api_minimal_version: null,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setOnline: (state, action) => {
      state.online = action.payload;
    },
    setApiMinimalVersion: (state, action) => {
      state.api_minimal_version = action.payload;
    },
  },
});

// export actions
export const { setOnline, setApiMinimalVersion } = appSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.app.value)`
export const selectOnline = (state) => state.app.online;
export const selectApiMinimalVersion = (state) => state.app.api_minimal_version;

// export reducers
export default appSlice.reducer;
