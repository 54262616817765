import React from 'react';
import { connect } from 'react-redux';
import * as serviceWorkerRegistration from './../serviceWorkerRegistration';
import { withStyles } from '@material-ui/core/styles';
import viewService from './../services/view';
import utilsService from './../services/utils';
import packageJson from './../package.alias.json';
import compareVersions from 'compare-versions';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import AutorenewIcon from '@material-ui/icons/Autorenew';

class AppUpdateController extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      waiting: false,
      showUpdateBanner: false,
    };
    this.swRegUpdateInterval = null;
    this.swRegUpdateFound = false;
    this.swReg = null;
  }

  componentDidMount () {
    this.register();
    this.survey();
  }

  componentWillUnmount () {
    this.stop();
  }

  register () {
    serviceWorkerRegistration.register({
      onSuccess: (registration) => {
        // SW installé (ou mis à jour), on surveille les nouvelles mise à jours
        this.survey();
      },
    });
  }

  survey () {
    if (!this.swRegUpdateInterval) {
      if (navigator.serviceWorker) {
        navigator.serviceWorker.getRegistration().then(registration => {
          if (registration) {
            this.swReg = registration;
            this.swRegUpdateInterval = setInterval(() => {
              if (!this.swRegUpdateFound) {
                registration.update()
              }
            }, 30000);
            // On écoute si une mise à jour du Service Worker est trouvée
            registration.addEventListener('updatefound', () => {
              // console.log("SW update found !!!");
              // On stoppe la recherche de mise à jour
              this.swRegUpdateFound = true
              clearInterval(this.swRegUpdateInterval);
              this.setState({
                showUpdateBanner: true
              });
            });
            // On écoute si une mise à jour du Service Worker est faite
            registration.addEventListener('updated', () => {
              // console.log("SW updated !!!");
            });
          }
        });
      }
    }
  }

  stop () {
    if (this.swRegUpdateInterval) {
      clearInterval(this.swRegUpdateInterval);
    }
    this.swRegUpdateFound = false;
  }

  update() {
    /*
     * On reload l'app en vidant les caches et en notifiant le service worker
     * si il a une mise à jour en attente.
     */
    viewService.showLoading('AppUpdateController');
    utilsService.reloadApp();
  }

  wait () {
    this.setState({
      waiting: true,
    });
    setTimeout(() => {
      this.setState({
        waiting: false,
      });
    }, 15000);
  }

  render () {
    const { appStore } = this.props;

    /**
     * À chaque appel à l'API, le serveur nous renvoit une version minimale de compatibilité de l'application.
     * Si cette version de l'application n'est pas compatible, on demande à l'utilisateur de mettre à jour l'app.
     * NOTE : bien entendu, il est convenu que Bridger propose toujours une version compatible de l'app avec son API,
     */
    let app_version_deprecated = false;
    const api_minimal_version = appStore.api_minimal_version || null;
    if (api_minimal_version && api_minimal_version !== undefined && api_minimal_version !== null && api_minimal_version !== '') {
      if (compareVersions(packageJson.version, api_minimal_version) < 0) {
        app_version_deprecated = true;
      }
    }

    const openUpdateBanner = (this.state.showUpdateBanner || app_version_deprecated) && !this.state.waiting

    return (
      <React.Fragment>
        {this.props.children}
        <Dialog
          open={openUpdateBanner}
          onClose={ () => this.wait() }
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Une mise à jour est disponible..."}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Si certaines actions en cours ne sont pas sauvegardés, vous pouvez repousser la mise à jour. Sinon il est conseillé de mettre à jour l'application pour obtenir les dernières nouveautés et éviter tout bug.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={ () => this.wait() } color="primary">
              Plus tard
            </Button>
            <Button variant="contained" color="primary" endIcon={<AutorenewIcon />} onClick={ () => this.update() }>
              Mettre à jour
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const styles = theme => ({});

const mapStateToProps = state => ({
	appStore: state.app,
});

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(AppUpdateController));
