import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './services/store';
// import authService from './services/auth';
import networkService from './services/network';
import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider } from 'notistack';
import AppUpdateController from './components/AppUpdateController';
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import blueGrey from '@material-ui/core/colors/blueGrey';
import CssBaseline from '@material-ui/core/CssBaseline';

// Date provider
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from "date-fns/locale/fr";

import Main from './views/Main';

class App extends React.Component {

  constructor (props) {
    super(props);
    // this.authServiceInterval = null;
    this.customTheme = createMuiTheme({
      palette: {
        primary: {
          dark: blueGrey[800],
          main: blueGrey[500],
          light: blueGrey[200],
        },
        secondary: {
          main: blueGrey[100],
        },
      },
    });
    this.customTheme.typography.h1 = {
      fontWeight: 'bold',
      fontSize: 22,
      marginBottom: 18,
    };
    this.customTheme.typography.h2 = {
      fontWeight: 'bold',
      fontSize: 18,
      marginBottom: 12,
    };
    this.customTheme.typography.h3 = {
      fontWeight: 'bold',
      fontSize: 16,
      marginBottom: 12,
      color: blueGrey[500],
    };
    this.customTheme.typography.h4 = {
      fontWeight: 'bold',
      marginBottom: 9,
      color: blueGrey[500],
    };
    this.customTheme.typography.body1 = {
      fontSize: 14,
    };
    this.customTheme.typography.body2 = {
      fontSize: 12,
    };
  }

  componentDidMount () {
    // Important : on controle ici l'état de connexion via le store
    // ainsi, cet état est accessible dans les vues via le store
    networkService.init();
    networkService.survey();
  }

  componentWillUnmount () {
    // clearInterval(this.authServiceInterval);
  }

  processUpdateApp () {
    window.location.reload();
  }

  render() {
    const { classes } = this.props;
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={this.customTheme}>
              <div className={classes.app} >
                <SnackbarProvider maxSnack={3} classes={{
                  containerRoot: classes.snackbar,
                  variantInfo: classes.snackbar_info,
                  variantSuccess: classes.snackbar_success,
                  variantWarning: classes.snackbar_warning,
                  variantError: classes.snackbar_error,
                }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={frLocale}>
                    <AppUpdateController>
                      <Router>
                        <CssBaseline />
                        <Main />
                      </Router>
                    </AppUpdateController>
                  </MuiPickersUtilsProvider>
                </SnackbarProvider>
              </div>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    );
  }
}

const styles = theme => ({
  app: {},
  snackbar: {
    marginBottom: theme.spacing(7),
  },
  snackbar_info: {
    backgroundColor: '#777777',
  },
  snackbar_success: {
    backgroundColor: '#555555',
  },
  snackbar_warning: {
    backgroundColor: '#333333',
  },
  snackbar_error: {
    backgroundColor: '#000000',
  },
});

export default withStyles(styles, { withTheme: true })(App);
