import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  title: '', // défini le titre d'une vue
	loadings: [], // défini les loaders d'une vue
};

export const viewSlice = createSlice({
  name: 'view',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    addLoading: (state, action) => {
      let loadings = state.loadings || [];
			if (!loadings.includes(action.payload)) {
				loadings.push(action.payload);
			}
      state.loadings = loadings;
    },
    removeLoading: (state, action) => {
      if (state.loadings) {
        state.loadings = state.loadings.filter((viewName) => {
          return viewName !== action.payload;
        });
      }
    },
  },
});

// export actions
export const { setTitle, addLoading, removeLoading } = viewSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.view.value)`
export const selectViewTitle = (state) => state.view.title;
export const selectViewLoadings = (state) => state.view.loadings;

// export reducers
export default viewSlice.reducer;
