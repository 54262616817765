import { store } from './store';
import { setApiMinimalVersion } from './store/features/appSlice';

const exportedObject = {
  stripHtmlTags (str) {
    return str.replace(/<\/?[^>]+(>|$)/g, "");
  },
  truncate(str, limit, end = '...') {
    let words = str.split(" ");
    if (words.length > limit) {
      return words.splice(0, limit).join(" ") + end;
    }
    return str;
  },
  dateStringToDate (dateString) {
    return new Date(Date.parse(dateString));
  },
  dateStringToDateTime (dateString) {
    return Date.parse(dateString);
  },
  dateStringToLocalString (dateString, short = true, hours = true) {
    let datetime = this.dateStringToDateTime(dateString);
    if (isNaN(datetime)) {
      return 'date incorrecte';
    } else {
      return this.dateTimeToLocalString(datetime, short, hours);
    }
  },
  dateTimeToLocalString (datetime, short = true, hours = true) {
    return this.dateToLocalString(new Date(datetime), short, hours);
  },
  dateToLocalString (date, short = true, hours = true) {
    let options = {
      weekday: short ? 'short' : 'long',
      day: 'numeric',
      month: short ? 'short' : 'long',
      year: 'numeric',
      timeZone: 'Europe/Paris',
    };
    if (hours) {
      options.hour = '2-digit';
      options.minute = '2-digit';
    }
    return date.toLocaleString('fr-FR', options);
  },
  dateToMysqlDateTime (date) {
    return date.getFullYear() + "-"
     + this.twoDigits(1 + date.getMonth())
      + "-" + this.twoDigits(date.getDate())
       + " " + this.twoDigits(date.getHours())
        + ":" + this.twoDigits(date.getMinutes())
         + ":" + this.twoDigits(date.getSeconds());
  },
  dateToMysqlDateTimeUTC (date) {
    return date.getUTCFullYear() + "-"
     + this.twoDigits(1 + date.getUTCMonth())
      + "-" + this.twoDigits(date.getUTCDate())
       + " " + this.twoDigits(date.getUTCHours())
        + ":" + this.twoDigits(date.getUTCMinutes())
         + ":" + this.twoDigits(date.getUTCSeconds());
  },
  dateDayName (date) {
    var days = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];
    return days[date.getDay()];
  },
  twoDigits(d) {
    if(0 <= d && d < 10) return "0" + d.toString();
    if(-10 < d && d < 0) return "-0" + (-1*d).toString();
    return d.toString();
  },
  stringSearch(string, search) {
    string = string.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    search = search.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    return string.search(search) > -1;
  },
  clearAppCache() {
    if (!window.caches) {
        console.warn("Impossible de vider les window.caches");
    }
    caches.keys().then((names) => {
        for (var index = 0; index < names.length; index++) {
            var name = names[index];
            caches.delete(name);
        }
    });
  },
  /**
   * Reload current application with this actions :
   *  - clear window.caches
   *  - clear api_minimal_version from store (it will be set in next API request)
   *  - notifiy SW if update is waiting
   *  - reload current location
   */
  reloadApp() {
    // clear window.caches
    this.clearAppCache();
    // clear api_minimal_version from store
    store.dispatch(setApiMinimalVersion(null));
    // notifiy SW if update is waiting
    if (navigator.serviceWorker) {
      navigator.serviceWorker.getRegistration().then(registration => {
        if (registration && registration.waiting) {
          registration.waiting.postMessage({type: 'SKIP_WAITING'});
        } else {
          console.warn("Aucune mise à jour de SW en attente");
        }
      });
    }
    // reload current location after 2s waiting
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  },
  getBaseURL() {
    return window.location.protocol + '//' + window.location.host;
  },
  getNextDate(next_day = "P1D", next_hour = "08", next_minute = "00") {
    let next_date = new Date();
    if (next_day.startsWith('P') && next_day.endsWith('D')) {
      next_date.setDate(next_date.getDate()+parseInt(next_day.replaceAll('P', '').replaceAll('D', '')));
    } else {
      let next_day_num = 1;
      switch (next_day) {
        case "next-mon": {
          next_day_num = 1;
          break;
        }
        case "next-tue": {
          next_day_num = 2;
          break;
        }
        case "next-wed": {
          next_day_num = 3;
          break;
        }
        case "next-thu": {
          next_day_num = 4;
          break;
        }
        case "next-fri": {
          next_day_num = 5;
          break;
        }
        case "next-sat": {
          next_day_num = 6;
          break;
        }
        case "next-sun": {
          next_day_num = 0;
          break;
        }
        default: {
          next_day_num = 1;
          break;
        }
      }
      // notre technique peut renvoyer le jour courant si le prochain jour est aujourd'hui,
			// ce n'est pas une incohérence, mais seulement si l'heure demandée est ultérieure à maintenant,
			// sinon on doit démarrer à demain 00:00 pour calculer la prochaine date
      let next_date_time_wished = new Date();
      next_date_time_wished.setHours(parseInt(next_hour));
      next_date_time_wished.setMinutes(parseInt(next_minute));
			if (next_date_time_wished < next_date) {
				next_date.setDate(next_date.getDate() + 1)
				next_date.setHours(0);
        next_date.setMinutes(0);
			}
      // calcul du prochain jour
      next_date.setDate(next_date.getDate() + (next_day_num + 7 - next_date.getDay()) % 7);
    }
    next_date.setHours(parseInt(next_hour));
    next_date.setMinutes(parseInt(next_minute));
    next_date.setSeconds(0);
    return next_date;
  }
};
export default exportedObject;
