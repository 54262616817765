import React, {Suspense, lazy} from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import routes from './../services/routes';
import {
  Switch,
  Route,
  withRouter
} from "react-router-dom";
import BackdropLoading from './../components/BackdropLoading';
import Backdrop from '@material-ui/core/Backdrop';
import SignalCellularConnectedNoInternet0BarIcon from '@material-ui/icons/SignalCellularConnectedNoInternet0Bar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const Hello = lazy(() => import('./Hello'));

class Main extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      sidebarOpen: false,
    };
  }

  render () {
    const { classes } = this.props;
    const userLoggedIn = this.props.authStore.user;
    const backdrop_loading_opened = this.props.viewStore.loadings && this.props.viewStore.loadings.length > 0 ? true : false;
    const backdrop_offline_opened = !this.props.appStore.online;

    return (
        <main className={classes.root}>

          { /*---- App Loading ------------------------------------------------------*/ }
          <BackdropLoading open={backdrop_loading_opened} />

          { /*---- App OffLine ------------------------------------------------------*/ }
          <Backdrop className={classes.backdrop} open={backdrop_offline_opened}>
            <Box className={classes.backdropContent}>
              <SignalCellularConnectedNoInternet0BarIcon color="inherit" />
              <Box>
                <Typography>pas de connexion</Typography>
              </Box>
            </Box>
          </Backdrop>

          <Suspense fallback={<BackdropLoading open={true} />}>
            <Switch>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  render={() => {
                    if (route.loggedIn !== true) {
                      return (
                        route.component
                      )
                    } else {
                      if (userLoggedIn) {
                        return (
                          route.component
                        )
                      }
                      return (
                        <Hello />
                      )
                    }
                  }}
                />
              ))}
            </Switch>
          </Suspense>
        </main>
    );
  }
}

const styles = theme => ({
  root: {
    display: 'flex',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1000,
    color: '#fff',
  },
  backdropContent: {
    textAlign: 'center',
  }
});

const mapStateToProps = state => ({
	appStore: state.app,
  authStore: state.auth,
  viewStore: state.view,
});

export default withRouter(withStyles(styles, { withTheme: true })(connect(mapStateToProps)(Main)));
